.svCard
{
    position: relative;
    top:100px;
    border-radius: 20px;
}
.svhead{
    margin-top: 10px;
}
.rounded
{
    width: 90vw;;
    height: 40vh;
    object-fit: cover;
   
}
.svhead
{
    color: black;
}
.svimg
{
   
    margin-top:90px;
}
.sline
{
    width:50%;
    height: 5px;
    margin-bottom: 20px;
    margin-top: 15px;
    background-color: #16A4DE;
}
.shadowbox
{
    box-shadow: 5px 5px 10px #0005;
    border-radius: 15px;
    padding: 5px;
    height: 300px;
    margin-bottom: 30px;
}
.serv p
{
    font-family: 'PoppinsRegular';
    font-size: 14px;
}