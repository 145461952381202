.teamMain{
   
    padding: 20px;
}

.card-1
{
    background-color: #ddd;
    height: 170px;
    width: 170px;
    position: relative;
    margin-top: 10px;
}

.cimg
{
    margin-top: 20px;
}

.ctext{
    position: absolute;
}

.subp
{

}
.subcard
{
    margin-top: 20px;
    margin-bottom: 20px;
    width:81%;
    background-color: #eee;
    padding: 20px;
    
}
