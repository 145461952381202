

    .scard1
    {
        border-radius: 15px;
       position: relative;
       
        margin-top: 50px;
        width: 300px;
        height: 215px;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        transition: all .5s ease-in-out;
    }

    .scard1:hover
    {
        margin-top: 20px;
    }
    .c-title
    {
        position: absolute;
        bottom: -7.5px;
        left: -8px;
        background-color: rgba(0, 0, 0, 0.575);
        color:rgb(255, 255, 255);
        padding: 5px;
       
        width:100%
    }
    .c-img
    {
        width: 300px;
        height: 215px;
    }