.whp
{
    position: fixed;
    bottom: 80px;
    right:15px;
} 
.rwbn
{
    background: none;
    border: none;
}
.wpn
{
    background-color: rgb(0, 131, 70);
    width:45px;
    height: 45px;
    padding: 5px;
    border-radius: 10px;
}

.cmbn0
{
   background-color: #fff;
   width:45px;
   height: 45px;
   padding: 9px;
   border-radius: 10px;
   box-shadow: 0px 5px 5px 0px rgba(59, 59, 59, 0.5);
}


