.blogs-sec
{
    margin-top: 120px;
}
.load
{
    height: 75vh;
    padding: 200px;
}
.card-blog-data
{
    margin-top: 150px;
}
.card-blog-data>p
{
    
    
}
pre
{
    font-family: 'PoppinsRegular';
    white-space: pre-wrap;
    word-break: keep-all
}
