.fixed-top
{
    background-color: #ffffff;
    color:rgb(154, 154, 154);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    padding-bottom: 30px;
}
.brand
{
    margin-left: 30px;
}
.blogo
{
    width: 200px;
    max-width: 50vw;
}

.icn1
{
    width:40px;
    padding: 5px;
    border-radius: 10px;
    background-color: #eee;
    position: relative;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.slink
{
    margin-right: 10px;
    margin-top: 15px;
   font-weight: 600;
   float: right;
   box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}
