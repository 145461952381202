.abtmain
{
   
    
}
.acard
{
    margin-top: 50px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}
.a-con
{
    background-color: #fff;
    padding: 10px;
    
}
.ac-img
{
    width:100%;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    height: 370px;
    object-fit: cover;
}
.abtsec
{
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.abtwall
{
    max-width: 100%;
    /* border: 8px solid rgb(0, 157, 255); */
    border-top: 0px;
    border-right: 0px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 15px;
}   

.line
{
    background-color: rgb(0, 157, 255);
    height: 6px;
    width:200px;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
