
.vscon
{
    background-color: #fff;

    position: relative;
}

.vhd
{
    border-radius: 50px;
    font-weight: bold;
}   

.vscard
{
    background-color: #eee;
    height: 350px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 5px;
}
.curve
{
    position: absolute;
    bottom: 0;
    left: 0;
}